/**
 * Messages sent from the Agave File Picker iframe to the parent window (i.e., client's app)
 */
import { Submission } from './Submission';

export enum FilePickerMessageType {
    Exit = 'agave.file-picker.exit', // User clicked on X to close the iframe, so parent window should remove the iframe
    Submit = 'agave.file-picker.submit', // User successfully submitted file selection
}

export interface FilePickerExitMessage {
    type: FilePickerMessageType.Exit;
    error?: string;
}

export interface FilePickerSubmitMessage {
    type: FilePickerMessageType.Submit;
    submission: Submission;
}
