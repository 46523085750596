// noinspection DuplicatedCode,JSUnusedGlobalSymbols

import { FilePickerExitMessage, FilePickerMessageType, FilePickerSubmitMessage } from 'App/FilePicker/types/Message';
import { Submission } from 'App/FilePicker/types/Submission';
import axios from 'axios';
import { CanHaveIframe, IframeContainer } from './IframeContainer';
interface OpenFilePickerConfig extends CanHaveIframe {
    session: string;
    projectId?: string;
    disabledFileIds?: string[];
    canSelectUpToNFiles?: number;
    shouldDisableSelectedFiles?: boolean;
    fileTypes?: string[];
    onSubmit?: (submission: Submission) => void;
    onExit?: (error?: string) => void;
}

/**
 * This class will be used by clients and should only expose functionality they should have access to
 */
class AgaveFilePicker extends IframeContainer<OpenFilePickerConfig, FilePickerExitMessage | FilePickerSubmitMessage>
{
    protected DEFAULT_IFRAME_ID = 'agave-file-picker-iframe';

    protected readonly eventListener = (event: MessageEvent<FilePickerExitMessage | FilePickerSubmitMessage>) =>
    {
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns
        if (event.origin !== import.meta.env.VITE_APP_URL)
        {
            return;
        }

        const { type } = event.data;

        switch (type)
        {
        case FilePickerMessageType.Submit:
            try
            {
                if (this.config.onSubmit)
                {
                    this.config.onSubmit(event.data.submission);
                }
            }
            finally
            {
                this.closeIframe();
            }
            break;

        case FilePickerMessageType.Exit:
            try
            {
                if (this.config.onExit)
                {
                    this.config.onExit(event.data.error);
                }
            }
            finally
            {
                this.closeIframe();
            }
            break;

        default:
            break;
        }
    };

    protected async generateIframeSource()
    {
        const embedId = await this.storeIframeConfigs();

        return `${ import.meta.env.VITE_APP_URL }/file-picker/embed/${ embedId }?session=${ this.config.session }`;
    }

    private async storeIframeConfigs(): Promise<string>
    {
        const response = await axios.post<{ embed_id: string }>(`${ import.meta.env.VITE_APP_URL }/file-picker/store-configs`, {
            session: this.config.session,
            projectId: this.config.projectId ?? '',
            embedded: this.isEmbedded(this.config),
            outerHeight: window.outerHeight,
            outerWidth: window.outerWidth,
            scrollX: window.scrollX,
            scrollY: window.scrollY,
            disabledFileIds: this.config.disabledFileIds ?? [],
            canSelectUpToNFiles: this.config.canSelectUpToNFiles,
            shouldDisableSelectedFiles: this.config.shouldDisableSelectedFiles,
            fileTypes: this.config.fileTypes ?? [],
        });

        return response.data.embed_id;
    }

    /**
     * DEPRECATED: use open() instead
     */
    public openFilePicker(config: OpenFilePickerConfig): void
    {
        this.open(config);
    }
}

declare global {
    // noinspection JSUnusedGlobalSymbols
    interface Window {
        AgaveFilePicker: AgaveFilePicker;
    }
}
window.AgaveFilePicker = new AgaveFilePicker();

export default AgaveFilePicker;
