export interface CanHaveIframe {
    iframeId?: string;
}

interface HasIframe {
    iframeId: string;
}

/**
 * This class will be used by clients and should only expose functionality they should have access to
 */
export abstract class IframeContainer<T extends CanHaveIframe, TMessage>
{
    protected config!: T;
    protected abstract DEFAULT_IFRAME_ID: string;

    private originalBodyStyles = {
        overflow: '',
        height: '',
    };

    protected abstract readonly eventListener: (event: MessageEvent<TMessage>) => void;
    protected abstract generateIframeSource(): Promise<string>;

    protected isEmbedded(config: T): config is (HasIframe & T)
    {
        if (this.config.iframeId && this.config.iframeId !== '')
        {
            if (document.getElementById(this.config.iframeId) instanceof HTMLIFrameElement)
            {
                return true;
            }
            console.error(`#${ this.config.iframeId } is not a valid <iframe> element.`);
        }

        return false;
    }

    protected closeIframe(): void
    {
        if (this.isEmbedded(this.config))
        {
            return;
        }

        window.removeEventListener('message', this.eventListener);
        document.body.style.overflow = this.originalBodyStyles.overflow;
        document.body.style.height = this.originalBodyStyles.height;
        document.getElementById(this.DEFAULT_IFRAME_ID)?.remove();
    }

    /**
     * Creates an iframe and appends it to the body
     */
    private createIframe(): HTMLIFrameElement
    {
        const iframe = document.createElement('iframe');

        iframe.id = this.DEFAULT_IFRAME_ID;
        iframe.height = '100%';
        iframe.width = '100%';
        iframe.style.display = 'block';
        iframe.style.position = 'fixed';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.right = '0';
        iframe.style.bottom = '0';
        iframe.style.zIndex = '9999999999';
        iframe.style.borderWidth = '0';
        iframe.style.overflowX = 'hidden';
        iframe.style.overflowY = 'auto';
        iframe.style.backgroundColor = 'transparent';

        document.body.appendChild(iframe);

        return iframe;
    }

    protected getIframe(): HTMLIFrameElement
    {
        return this.isEmbedded(this.config)
            ? document.getElementById(this.config.iframeId) as HTMLIFrameElement
            : this.createIframe();
    }

    private async setupIframe()
    {
        const iframe = this.getIframe();

        iframe.src = await this.generateIframeSource();

        // URGENT: Always add a corresponding window.removeEventListener call in @closeIframe
        window.addEventListener('message', this.eventListener);

        if (this.isEmbedded(this.config))
        {
            return;
        }

        this.originalBodyStyles.overflow = document.body.style.overflow || '';
        this.originalBodyStyles.height = document.body.style.height || '';
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
    }

    /**
     * Creates an iframe and overlays it on top of the current page
     */
    public open(config: T): void
    {
        this.config = config;
        void this.setupIframe(); // Just call the async function, but don't wait for its results
    }
}
